import { FC, useMemo } from "react";
import joinClassNames from "classnames";

import MenuVerticalIcon from "icons/menu-vertical.svg?react";

import Button from "basics/button";

import useWindowSizes from "hooks/use-window-sizes";

import Search from "components/search";
import Tabs from "components/tabs";
import List from "components/list";

import { openCategoriesDrawer } from "./components/categories-drawer";
import { useCategories } from "./duck/hooks";
import { useCoinsURLParams } from "../../duck/hooks";

import classes from "./styles/classes.module.scss";

const Header: FC = () => {
  const { isMobileWidth } = useWindowSizes();
  const categories = useCategories();
  const { search, params, setAllParams, setSearchParams } = useCoinsURLParams();

  const categoryLabel = useMemo(
    () => categories.find(item => item.value === params.category).label,
    [categories, params.category],
  );

  const title = (
    <h1 className={classes.title}>
      {isMobileWidth ? `${categoryLabel} coins` : "Coins"}
    </h1>
  );

  return (
    <div className={classes.wrapper}>
      <div className={joinClassNames(classes.headerPaddings, classes.header)}>
        {isMobileWidth ? (
          <div className={classes.row}>
            {title}
            <Button
              title="Choose category"
              isIconOnly
              themeName="ghost"
              onClick={async () => {
                const resultCategory = await openCategoriesDrawer(
                  params.category,
                );
                if (!resultCategory) {
                  return;
                }

                setAllParams({ params: { category: resultCategory } });
              }}
            >
              <MenuVerticalIcon className={classes.menuIcon} />
            </Button>
          </div>
        ) : (
          title
        )}

        <Search
          classNames={{ wrapper: classes.searchWrapper }}
          className={classes.search}
          name="cryptocurrency"
          placeholder="Search for coins"
          initialValue={search.search}
          onSearchChange={search => setSearchParams({ search })}
        />
      </div>
      {!isMobileWidth && (
        <Tabs
          items={categories}
          value={params.category}
          className={joinClassNames(
            classes.headerPaddings,
            classes.tabsWrapper,
          )}
          onChange={({ value }) =>
            setAllParams({ params: { category: value } })
          }
        />
      )}

      <List.Header>
        <List.HeaderCell sortKey="rank" reverseDefaultSort>
          #
        </List.HeaderCell>
        <List.HeaderCell sortKey="name">NAME</List.HeaderCell>
        <List.HeaderCell sortKey="price">PRICE</List.HeaderCell>
        <List.HeaderCell>MARKET CAP</List.HeaderCell>
        <List.HeaderCell sortKey="percent_24">24H %</List.HeaderCell>
        <List.HeaderCell>7D %</List.HeaderCell>
        <List.HeaderCell align="right" />
        <List.HeaderCell align="right" />
      </List.Header>
    </div>
  );
};

export default Header;
