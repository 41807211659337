import { FC, useState } from "react";

import Button from "basics/button";
import Radio, { RadioDot } from "basics/radio";

import { Drawer, useCloseInDesktop, useDialog } from "components/dialog";

import { useCategories } from "../../duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props {
  initialCategory?: string;
}

const CategoriesDrawer: FC<Props> = ({ initialCategory }) => {
  const [category, setCategory] = useState(initialCategory);
  const { resolve } = useDialog();
  const categories = useCategories();

  useCloseInDesktop(() => resolve(null));

  return (
    <>
      <Drawer.Header>
        <p className={classes.title}>Categories</p>
      </Drawer.Header>
      <Drawer.Body>
        <Radio
          className={classes.radioGroup}
          value={category}
          onChange={category => {
            setCategory(category);
          }}
          items={categories}
          option={({ item }) => (
            <>
              <p className={classes.value}>{item.label}</p>
              <RadioDot size="md" />
            </>
          )}
        />
      </Drawer.Body>
      <Drawer.Footer>
        <Button
          isBlock
          themeName="primary"
          onClick={() => {
            resolve(category);
          }}
        >
          Apply
        </Button>
      </Drawer.Footer>
    </>
  );
};

export default CategoriesDrawer;
