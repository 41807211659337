import dialogAPI from "operations/dialog";

import { Drawer } from "components/dialog";

import CategoriesDrawer from "./CategoriesDrawer";

const openDrawer = (initialCategory?: string) =>
  dialogAPI.openDrawer<string | null>(
    <Drawer placement="bottom">
      <CategoriesDrawer initialCategory={initialCategory} />
    </Drawer>,
  );

export default openDrawer;
